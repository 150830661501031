<template>
  <div class="content-wrapper">
    <bo-page-header></bo-page-header>
    <div class="content pt-0">
      <b-row cols="1" cols-md="5">
        <b-col>
          <div class="board_header bg_planned">
            <h3>Planned</h3>
          </div>
          <div class="card_board">
            <h4>Meeting A</h4>
            <h5>20-05-2020 15:00</h5>
            <ul>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
            </ul>
          </div>

          <div class="card_board">
            <h4>Call Customer</h4>
            <h5>20-05-2020 15:00</h5>
            <ul>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
            </ul>
          </div>
          <button data-toggle="modal" data-target="#modal_large" type="button" class="btn mt-2 btn-block btn-light">
            <i class="icon-plus2 mr-2"></i>Add Activity</button>
        </b-col>

        <b-col>
          <div class="board_header bg_today">
            <h3>Today</h3>
          </div>
          <div class="card_board">
            <h4>Meeting A</h4>
            <h5>20-05-2020 15:00</h5>
            <ul>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
            </ul>
          </div>
          <button data-toggle="modal" data-target="#modal_large" type="button" class="btn mt-2 btn-block btn-light">
            <i class="icon-plus2 mr-2"></i>Add Activity</button>
        </b-col>

        <b-col>
          <div class="board_header bg_done">
            <h3>Done</h3>
          </div>
          <div class="card_board">
            <h4>Meeting A</h4>
            <h5>20-05-2020 15:00</h5>
            <ul>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
            </ul>
          </div>
          <button data-toggle="modal" data-target="#modal_large" type="button" class="btn mt-2 btn-block btn-light">
            <i class="icon-plus2 mr-2"></i>Add Activity</button>
        </b-col>

        <b-col>
          <div class="board_header bg_cancelled">
            <h3>Cancelled</h3>
          </div>
          <div class="card_board">
            <h4>Meeting A</h4>
            <h5>20-05-2020 15:00</h5>
            <ul>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
            </ul>
          </div>
          <div class="card_board">
            <h4>Call Customer</h4>
            <h5>20-05-2020 15:00</h5>
            <ul>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
            </ul>
          </div>
          <button data-toggle="modal" data-target="#modal_large" type="button" class="btn mt-2 btn-block btn-light">
            <i class="icon-plus2 mr-2"></i>Add Activity</button>
        </b-col>

        <b-col>
          <div class="board_header bg_other">
            <h3>Other</h3>
          </div>
          <div class="card_board">
            <h4>Meeting A</h4>
            <h5>20-05-2020 15:00</h5>
            <ul>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
              <li>
                <div class="wrap_rsp"></div>
              </li>
            </ul>
          </div>
          <button data-toggle="modal" data-target="#modal_large" type="button" class="btn mt-2 btn-block btn-light">
            <i class="icon-plus2 mr-2"></i> Add Activity</button>
        </b-col>
      </b-row>
    </div>
    <bo-footer></bo-footer>
  </div>
</template>
<script>
import GlobalVue from '../../libs/Global.vue'

export default {
  extends: GlobalVue,
}
</script>
